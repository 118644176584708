@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500&display=swap');

body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .navigation-menus > li > a {
    font-family: 'Prompt', 'Open Sans', 'Roboto', sans-serif;
}

body {
    font-size: 14px;
    font-weight: 400;
}

.topbar-menu {
    padding-top: 75px;
    background-color: #f4f4f4;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
}

a {
    cursor: pointer;
}

.h3, .h4, .h5, .h6, h3, h4, h5, h6, b, strong, .dropdown-item, .navigation-menus, .btn, .form-control, label, .font-weight-bold, .radio > label, .label {
    font-weight: 400;
}

.h2, h2 {
    font-weight: 400;
}

.h1, h1 {
    font-weight: 500;
}

.logo img {
    margin-top: -10px;
}

.logo-text, .logo-text:hover, .logo-text:active {
    font-size: 20px;
    font-weight: 400;
    color: #FFF;
}

.logo-text-outline {
    font-weight: 400;
    color: #343a40;
}

/*
body, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  font-family: Helvetica, sans-serif;
}
*/

h4.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}

h2.header-title {
    font-size: 1.875rem !important;
    margin: 0 0 7px 0;
}

h3.header-title {
    font-size: 1.875rem;
    margin: 0 0 20px 0;
}

.nav-bar {
    font-size: 16px;
    font-weight: 400;
    margin-right: 30px;
}

.i-device {
    font-size: 24px;
    margin-right: 30px;
    cursor: pointer;
}

.mobile-device-frame {
    height: 777px;
    padding: 20px 15px;
    width: 400px;
    /*transform: scale(0.885014);*/
    transition: transform .3s cubic-bezier(.694, 0, .335, 1);
    pointer-events: auto;
    left: calc(50% - 200px);
    position: relative;
}

.mobile-device {
    width: 410px;
    display: block;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    bottom: 0;
    box-shadow: 0 0 100px rgba(0, 0, 0, .07);
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}

.mobile-device-home {
    border: 1px solid #f6f6f6;
    border-radius: 100%;
    bottom: 20px;
    display: inline-block;
    height: 45px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 45px;
}

.iframe-mobile-device {
    z-index: 9999;
    width: 380px;
    height: 680px;
    border: 0;
}

.tablet-device-frame {
    height: 620px;
    padding: 20px 20px 40px 40px;
    width: 92 0px;
    transition: transform .3s cubic-bezier(.694, 0, .335, 1);
    pointer-events: auto;
    left: calc(50% - 490px);
    position: relative;
}

.tablet-device {
    width: 980px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    bottom: 0;
    box-shadow: 0 0 100px rgba(0, 0, 0, .07);
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
}

.tablet-device-home {
    border: 1px solid #e1e1e1;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    height: 45px;
    position: absolute;
    right: 34px;
    top: 50%;
    transform: translateY(-50%);
    width: 45px;
}

.iframe-tablet-device {
    z-index: 9999;
    width: 768px;
    height: 580px;
    border: 0;
}

.template-page .card-body {
    min-height: 100vh;
}

.cover .desktop-device-frame {
    height: 700px;
}

.desktop-device-frame {
    height: 84vh;
    padding: 30px 0 0;
    width: 1155px;
    transition: transform .3s cubic-bezier(.694, 0, .335, 1);
    pointer-events: auto;
    position: relative;
    left: calc(50% - 580px);
}

.desktop-device-frame-bg {
    left: calc(50% - 580px) !important;
}

.desktop-device {
    width: 1155px;
    background: #fff;
    border: 1px solid #eee;
    bottom: 0;
    box-shadow: 0 0 100px rgba(0, 0, 0, .07);
    display: block;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 10px;
}

.sites .desktop-device-home {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    height: 6px;
    width: 6px;
    display: inline-block;
    margin: 11px 6px 0px;
}

.desktop-device-home {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    height: 6px;
    width: 6px;
    display: inline-block;
    margin: 11px 6px;
}

.iframe-desktop-device {
    z-index: 9999;
    width: 1480px;
    height: 100vh;
    border: 1px solid #ebebeb;
    -ms-transform: scale(0.78);
    -moz-transform: scale(0.78);
    -o-transform: scale(0.78);
    -webkit-transform: scale(0.78);
    transform: scale(0.78);

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

.i-device:hover, .i-device.active {
    color: #2CA3F8;
}

.row-create-site {
    margin: 70px 0 0 0;
}

.row-create-site .col {
    padding: 20px;
}

.row-create-site .selector-or-word {
    color: #000;
    text-align: center;
}

.card-pricing {
    min-height: 640px;
    border: 1px solid #dbdbdb;
}

.card-pricing-singlepage {
    min-height: 600px;
}

.card-pricing.recommended {
    border: 2px solid #1abc9c;
}

.card-pricing .card-pricing-plan-name {
    padding-bottom: unset;
}

.card-pricing .sub-header {
    min-height: 64px;
    margin-bottom: 12px;
}

.card-pricing .card-pricing-features {
    color: #333333;
    text-align: left;
}

.card-pricing .card-pricing-features .not, .card-pricing .card-pricing-features i {
    color: #ABB2B8;
}

.authentication {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    /*min-height: 100vh;*/
}

#topnav {
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
}

.footer {
    color: #ffffff;
    background-color: #000000;
    border-top: unset;
    display: none;
}

.footer-alt {
    color: rgba(255, 255, 255, 1) !important;
    background-color: #000;
}

.line-height-1-4 {
    line-height: 1.4;
}

a {
    color: #467fcf;
    text-decoration: none;
    background-color: transparent;
}

.topbar-dark .navbar-custom {
    background-color: rgba(0, 0, 0, 1) !important
}

.topbar-dark .navbar-custom .topnav-menu .nav-link {
    color: rgba(255, 255, 255, 1) !important;
}

.menubar-light #topnav .has-submenu.active > a {
    color: #000;
}

.topbar-button {
    padding-top: 10px;
}

.no-navbar {
    padding-top: 80px;
}

.no-navbar-front {
    padding-top: 0px;
}

.navbar-custom {
    /*height: 64px;*/
}

.navbar-custom .topnav-menu .nav-link {
    font-size: 15px;
    line-height: 64px;
    max-height: 64px;
    font-size: 400;
}

.navbar-custom .btn, .gall-info .btn {
    box-shadow: unset;
}

.btn-primary:hover {
    color: #000000;
    background-color: #ffffff;
}

.btn-success:hover {
    color: #09757a;
    background-color: #ffffff;
    border: solid 1px #09757a;
}

.btn-outline-black {
    background-color: #ffffff;
}

.btn-outline-black:hover {
    background-color: #ffffff;
}

.btn-outline-black:hover {
    color: #333333;
}

.btn-black:hover {
    color: #dee2e6;
}

/*.col-template-filter{
  border-right: 1px solid rgba(32,48,60,.1);
}*/

.ul-template-filter {
    cursor: pointer;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.ul-template-filter li {
    padding: 0 0 0 10px;
    line-height: 42px;
    transition-duration: .2s;
    transition-property: text-indent;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
    padding: 2px 10px;
}

.ul-template-filter li.select, .ul-template-filter li:hover {
    /*background-color: rgba(32,48,60,.1);*/
    /*background-color: #000;
    color: #fff;*/
    color: #2CA3F8;
    border-color: #2CA3F8;
    font-weight: 400;
}

.col-template-filter hr {
    width: 100%;
    margin: 30px 0 30px 0;
    height: 1px;
    border: none;
    background-color: rgba(32, 48, 60, .1);
}

.template-link {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.template-link ul {
    font-size: 19px;
    width: auto;
    height: auto;
    outline: none;
    text-align: center;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-box-align: center;
    align-items: center;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.5px;
    line-height: 22px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    color: #000;
    padding: 80px 0 0 0;
    overflow: hidden;
    transform: translate3d(0px, 0px, 0px) scale(1, 1);
    transition: transform 50ms ease 0s;
    min-height: 55px;
    list-style: none;
}

.portfolio-link ul {
    padding: 180px 0 0 0;
}

.template-link li {
    padding: 0 0 0 5px;
    line-height: 42px;
    transition-duration: .2s;
    transition-property: text-indent;
}

.template-link li:hover {
    text-decoration: underline;
}

.template-link a {
    color: #000;
}

.container-fluid .sites {
    padding-right: 18px;
    padding-left: 18px;
}

.container-fluid .sites .gal-box {
    margin-bottom: 36px;
}

.gal-box .image-popup {
    padding: 0;
}

.image-container:hover .template-link {
    display: block;
    background: rgba(255, 255, 255, 0.96) !important;
}

.gall-info .btn {
    color: #6c757d;
}

.gal-box .image-popup img {
    cursor: -webkit-zoom-in;
    cursor: pointer;
}

.gal-box .gall-info {
    border: unset;
    padding: 20px;
}

.gal-box .gall-info {
    background-color: #ffffff;
    box-shadow: 0 0 95px 0 rgb(0 0 0 / 10%);
}

.gall-info a {
    font-size: 13px;
}

.gal-box .gall-info h4 {
    margin: 0;
    font-size: 18px;
    line-height: 1.33em;
    font-weight: 400;
}

.img-fluid-template {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 100% 0;
    /*padding: 10px;*/
}

.img-fluid-portfolio {
    height: 100%;
}

.image-container .image-popup .goto {
    font-size: 20px;
    width: 100%;
    height: auto;
    outline: none;
    text-align: center;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-align-items: center;
    align-items: center;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 400;
    -webkit-justify-content: center;
    justify-content: center;
    letter-spacing: .5px;
    line-height: 22px;
    text-decoration: none;
    text-transform: uppercase;
    background-color: initial;
    color: #343a40;
    padding: 100px 0 0;
    overflow: hidden;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
    transition: -webkit-transform 50ms ease 0s;
    transition: transform 50ms ease 0s;
    transition: transform 50ms ease 0s, -webkit-transform 50ms ease 0s;
    min-height: 55px;
    list-style: none;
}

.image-container .image-popup .btn, .image-container a.image-popup {
    color: #343a40;
}

.image-container .image-popup .goto:hover {
    text-decoration: underline;
}

.img-fluid {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 0 0;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-container .after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #343a40;
}

.image-container:hover .after {
    display: block;
    background: rgba(255, 255, 255, 0.9);
}

.image-container div.btn {
    position: absolute;
    top: 55%;
    left: 33%;
}

.qodef-tli-text {
    padding: 20px 10px;
}

.qodef-tli-title {
    margin: 0;
    font-size: 18px;
    line-height: 1.33em;
    font-weight: 400;
}

.qodef-tli-subtitle {
    margin: 0;
    font-size: 15px;
    line-height: 25px;
}

.page-builder-logo {
    position: absolute;
    right: 0;
    padding: 15px 15px 0 0;
}

.page-builder-logo img {
    border: 1px solid #FFF;
}

.border-right-1 {
    border-right: 1px solid #dbdbdb;
}

.colla-list {
    list-style: none;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    margin: 0;
    padding: 0;
    padding-left: 0 !important;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(219, 219, 219, 0.08);
    box-shadow: 0px 0px 4px 0px rgba(219, 219, 219, 0.08);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.colla-list li:not(:last-child) {
    border-bottom: none;
}

.colla-list > li:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
}

.colla-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: flex;
    align-content: flex;
    padding: 0;
}

.colla-list > li {
    padding: 0px 20px 0px 20px;
}

.colla-list > li.period {
    padding: 20px;
}

.colla-list li.period > div {
    padding: unset;
}

.colla-list > li:before, .colla-list > li:after {
    content: " ";
    display: table;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
}

.colla-list li img.profile-img {
    height: 32px;
    width: 32px;
}

.colla-list li .name {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.colla-list li .dns-type {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.colla-list li .dns-name {
    border-left: 1px solid #dbdbdb;
    -ms-flex-preferred-size: 150px;
    flex-basis: 150px;
    text-align: center;
}

.colla-list li .dns-value {
    text-align: center;
    border-left: 1px solid #dbdbdb;
    -ms-flex-preferred-size: 55px;
    flex-basis: 240px;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.colla-list li > div {
    padding: 20px;
}

.colla-list li .type {
    border-left: 1px solid #dbdbdb;
    -ms-flex-preferred-size: 150px;
    flex-basis: 150px;
}

.colla-list li .remove {
    border-left: 1px solid #dbdbdb;
    -ms-flex-preferred-size: 55px;
    flex-basis: 55px;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.delete-medium {
    background-position: -340px -60px;
    width: 0px;
    height: 15px;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.help {
    margin-bottom: 10px;
    line-height: 1.8;
    padding-left: 30px;
}

.help.shift-down {
    margin-top: 28px;
}

.help.shift-down ul {
    padding-top: 10px;
}

.display-flex-center {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
}

.dns-status-txt {
    padding: 20px;
    text-align: center;
}

/*Loader*/
.action-preloader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
}

.centered .action-preloader .status {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.spinner-loader {
    margin: 0 auto 0;
    width: 70px;
    text-align: center;
}

.spinner-loader > div {
    width: 18px;
    height: 18px;
    background-color: #2CA3F8;
    margin-right: 2px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-loader .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner-loader .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.page-title-box .col-lg-7, .page-title-box .col-lg-5 {
    width: 50% !important;
}

.mt-lg-3, .my-lg-3 {
    margin-top: 1.5rem !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.p-20 {
    padding: 10px 20px;
}

.pl-15 {
    padding-left: 15px;
}

.pb-15 {
    padding-bottom: 15px;
}

.w-100 {
    width: 100%;
}

.ic-payment {
    font-size: 30px;
    position: absolute;
    right: 30px;
}

.dashboard-min-h {
    min-height: 366px;
}

.dashboard-hits-h {
    min-height: 547px;
}

.dashboard-user-online {
    font-size: 96px;
    margin-top: 90px;
}

.ct-grid-background, .ct-line {
    fill: none;
}

.ct-point {
    stroke-width: 10px;
    stroke-linecap: round;
}

.ct-chart-pie .ct-label, .ct-chart-donut .ct-label {
    fill: #fff !important;
    color: #fff !important;
    font-size: 12px;
    line-height: 1;
}

.ct-chart-donut .diskuse-0 path {
    fill: #1abc9c !important;
}

.ct-chart-donut .diskuse-40 path {
    fill: #f7b84b !important;
}

.ct-chart-donut .diskuse-80 path {
    fill: #f05b4f !important;
}

.ct-chart-donut .diskuse-total path {
    fill: #D6DADE !important;
}

.sec-toast {
    position: fixed;
    top: 140px;
    right: 40px;
    z-index: 99999;
}

.toast {
    min-width: 250px;
}

.modal-header {
    border-bottom: 0px;
}

.modal-footer {
    border-top: 0px;
}

.input-confirm {
    width: 94%;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.sweetalertbtn {
    width: 50%;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.12) !important;
    border-color: #1abc9c !important;
}

/* LoaderSpinner */
.action-preloaderspinner {
    display: block;
    visibility: visible;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2147483647;
    padding: 0px;
    margin: 0px;
    border: 0px none transparent;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: hidden auto;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.2s ease 0s;
}

.lds-spinner {
    color: official;
    display: inline-block;
    width: 40px;
    height: 40px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.lds-spinner div {
    transform-origin: 20px 20px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 2px;
    left: 19px;
    width: 3px;
    height: 9px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Form Wizard*/
ol.progtrckr {
    list-style-type: none;
    padding: 0;
    text-align: center;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
}

ol.progtrckr li span {
    padding: 0 1.5rem;
}

@media (max-width: 650px) {
    .progtrckr li span {
        display: none;
    }
}

.progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
}

@media (max-width: 650px) {
    .progtrckr em {
        display: inline;
    }
}

ol.progtrckr li.progtrckr-todo {
    color: silver;
    border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
    color: #CCCCCC;
    border-bottom: 4px solid #CCCCCC;
}

ol.progtrckr li.progtrckr-done {
    color: #1abc9c;
    border-bottom: 4px solid #1abc9c;
}

ol.progtrckr li:after {
    content: "\00a0\00a0";
}

ol.progtrckr li:before {
    position: relative;
    bottom: -4.2rem;
    float: left;
    left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
    color: silver;
}

ol.progtrckr li.progtrckr-doing:before {
    content: "\2022";
    color: white;
    background-color: #CCCCCC;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
    color: silver;
}

ol.progtrckr li.progtrckr-done:before {
    content: "\2022"; /*"\2713";*/
    color: white;
    background-color: #1abc9c;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
}

.pull-right {
    float: right !important;
}

.colla-domain-list li {
    display: block !important;
}

.colla-domain-list .input-group {
    padding: 0px 20px 20px !important;
}

.col.dropdown-item {
    padding: unset !important;
}

.dropdown-icon-item {
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: #6c757d;
}

.dropdown-icon-item img {
    height: 24px;
    vertical-align: middle;
    border-style: none;
}

.dropdown-icon-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

ul.pagination {
    display: flex;
    justify-content: center;
}

ul.pagination li {
    font-size: 18px;
    padding: 10px;
}

ul.pagination .active {
    color: #000000;
    font-weight: 400;
}

ul.pagination .active:focus {
    text-decoration: underline;
}

*:focus {
    outline: none;
}

.iframe_container {
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: #fff;
    top: 0px;
    left: 0px;
    position: absolute;
}

.notification-list .sitelist-dropdown .notify-item {
    padding: 7px 20px !important;
}

.sitelist-dropdown {
    min-width: 280px;
}

.navbar-custom .app-search {
    max-width: 260px !important;
}

.navbar-custom .app-search .input-group-append {
    padding-top: 10px;
}

.navbar-custom .app-search .form-control {
    width: 224px;
}

.hdr-search-text {
    color: #202020;
    font-weight: 400;
}

h2 {
    font-weight: 400;
}

.dropdown-project {
    margin-top: 10px;
    padding: 0.45rem 0.45rem;;
}

.dropdown-project:hover {
    color: #FFF;
}

.row-img-profile {
    padding: 30px 0;
}

.row-img-profile img {
    width: 70px;
}

.row-img-profile button {
    margin-left: 30px;
}

.row-img-profile div {
    margin-top: 10px;
}

.template-page {
    padding: 30px 0px;
}

.display-none {
    display: none;
}

.g-signin {
    border-radius: 0px !important;
    width: 100% !important;
    padding: 4px !important;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%) !important;
    border: 1px solid #ced4da !important;
}

.g-signin span {
    font-family: 'Mitr', 'Open Sans', 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 14px !important;
}

.fb-singin {
    font-family: 'Mitr', 'Open Sans', 'Roboto', sans-serif !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    border-radius: 0px !important;
    width: 100% !important;
    padding: 4px !important;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%) !important;
    border: 1px solid #ced4da !important;
    text-align: left;
    color: #fff;
    background-color: #3b5998;
}

.facebook-button {
    display: inherit;
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 2px;
    width: 38px;
    height: 41px;
}

.buttonSvgImage {
    width: 15px;
    height: 23px;
    background-image: url(./assets/images/facebook-logo.png);
}

.login-or {
    display: table;
    margin: 0 0 30px;
    font-weight: 400;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    background: transparent;
}

.login-or::after, .login-or::before {
    position: relative;
    top: 50%;
    display: table-cell;
    width: 50%;
    border-top: 1px solid #e8e8e8;
    transform: translateY(50%);
    content: '';
    box-sizing: border-box;
}

.ant-divider-inner-text {
    display: inline-block;
    padding: 0 24px;
}

.socal-login {
    margin: 10px 0px 0px;
}

.btn-period {
    margin: 0 0 20px !important;
}

.bg-soft-s-green {
    background-color: rgba(68, 207, 156, 0.25);
}

.text-s-green {
    color: #44cf9c !important;
}

#subscription-term {
    padding: 40px 0 0;
}

.pricing-tabs-toggle_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: inline-flex;
    overflow: hidden;
    margin: auto;
    border: 1px solid #ccc;
    border-radius: 50px;
    padding: 2px;
}

.pricing-tabs-purple-toggle_menu-item {
    padding: 10px 30px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: #343a40;
    padding: 6px 60px;
}

.pricing-tabs-toggle_wrapper span.active {
    color: #fff;
    background-color: #343a40;
    border: 1px solid #343a40;
    border-radius: 50px;
}

.plans-list {
    margin: 30px 0 0 0;
}

.plans-list thead th {
    cursor: pointer;
    vertical-align: top;
}

.plans-list thead th span.active {
    color: #fff !important;
    background-color: #2CA3F8 !important;
    border: 1px solid #2CA3F8 !important;
}

.plans-list thead th.active {
    border-top: 3px solid #2CA3F8;
    border-left: 3px solid #2CA3F8;
    border-right: 3px solid #2CA3F8;
}

.plans-list thead th.text-center {
    width: 20%;
}

.plans-list thead th div {
    font-size: 14px;
    font-weight: 200;
    min-height: 70px;
}

.plans-list thead th h4 {
    font-size: 28px;
    font-weight: 500;
}

.plans-list thead th h4 span {
    font-size: 14px;
}

.plans-list tbody th {
    font-weight: 400;
}

.plans-list tbody td {
    text-align: center;
}

.plans-list tbody td.active {
    border-left: 3px solid #2CA3F8;
    border-right: 3px solid #2CA3F8;
}

.plans-list tbody tr.tr-footer td.active {
    border-bottom: 3px solid #2CA3F8;
}

i.icon-user {
    color: #707070;
    border: 1px solid #707070;
    border-radius: 50px;
    padding: 6px;
}

.notification-list .waves-effect{
    overflow: visible !important;
}

.img-user {
    border-radius: 50%;
    height: 29px !important;
    width: 29px !important;
}

.text-center {
    text-align: center;
}

.site-list-display-icon {
    font-size: 20px;
    padding: 0 10px;
    cursor: pointer;
}

.site-list i.active {
    font-weight: 500;
    color: #2CA3F8;
}

.display-inline {
    display: inline;
}

.mr-30 {
    margin-right: 30px;
}

.table-site-list {
    margin: 0 18px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 6%);
    background-color: #fff;
}

.table-site-list thead {
    background-color: #fff !important;
    color: rgba(107, 114, 128, 1);
}

.table-site-list thead th {
    font-size: 15px;
    padding: 1.4rem;
    border-bottom: 0px;
}

.table-site-list td {
    padding: 1.4rem;
    /*background-color: #f7f8f9;*/
}

.table-site-list td a {
    color: rgba(107, 114, 128, 1);
    font-weight: 400;
}

.table-site-list i {
    color: #6c757d;
    font-size: 20px;
    padding: 0 5px;
    cursor: pointer;
}

.site_plan_1, .site_plan_2, .site_plan_3, .site_plan_4 {
    text-align: center;
    padding: 2px 10px;
    border-radius: 5px;
    background-color: #FFF;
}

.table-site-list .site_plan_1 {
    color: #e3c458;
    border: #e3c458 solid 1px;
}

.table-site-list .site_plan_2 {
    color: #5cc29d;
    border: #5cc29d solid 1px;
}

.table-site-list .site_plan_3 {
    color: #5e5bc2;
    border: #5e5bc2 solid 1px;
}

.table-site-list .site_plan_4 {
    color: #d16c56;
    border: #d16c56 solid 1px;
}

.primary-color {
    color: #2CA3F8 !important;
}

.color-notify-1 {
    color: #5cc29d !important;
}

.color-notify-2 {
    color: #e3c458 !important;
}

.color-notify-3 {
    color: #d16c56 !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.pointer {
    cursor: pointer;
}

.font-light th {
    font-weight: 300 !important;
}

.primary-menu {
    /*padding-top: 18px;*/
}

.primary-menu a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    padding: 0 8px;
}

.primary-menu a:hover {
    color: #4F9CF9;
}

.menubar-light .navigation-menus > li > a, .navigation-menus > li > a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.menubar-light .navigation-menus > li > a.active, .menubar-light .navigation-menus .in button {
    font-weight: 500;
    color: #4F9CF9 !important;
    /*text-decoration: underline;*/
}

.boder-color-white {
    border-color: #fff !important;
}

.app-search-box .input-group {
    background: #EFEFEF;
    opacity: 0.5;
    border-radius: 5px;
}

.app-search-box .input-group .form-control {
    background-color: #EFEFEF !important;
    color: rgba(0, 0, 0, 0.5) !important;
}

.app-search-box .input-group .input-group-append {
    padding-right: 10px;
}

#topnav .btn {
    font-size: 16px;
    font-weight: 500;
    /*margin-left: 10px;*/
}

.page-title-box .page-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 20px;
}

.page-title-box .sub-header {
    margin-top: -10px;
}

.page-title-box .breadcrumb {
    padding: 30px 0px;
}

.page-title-box .page-header {
    line-height: 32px;
}

.breadcrumb a, .breadcrumb-item {
    font-size: 14px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.5);
}

.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 9px;
    color: rgba(0, 0, 0, 0.5);
    content: "\f142";
}

.breadcrumb-item.active {
    color: #4F9CF9;
}

.header-title {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 27px !important;
    color: #000000 !important;
}

.sub-header {
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 30px !important;
    color: rgba(0, 0, 0, 0.5) !important;
}

.div-body {
    display: flex;
    padding: 0 0 30px 0;
}

.div-col {
    padding: 0 100px 0 0;
}

.div-body h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

.div-body .link {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;

    /* color/blue/400 */

    color: #4F9CF9;
}

.site_plan .color-notify-1 {
    color: #4F9CF9 !important;
}

.process-step path {
    fill: #4F9CF9 !important;
}

.process-total path {
    fill: #EFEFEF !important;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
    border: 0;
    border-top: 1px solid #EFEFEF;
}

.pd-t-50 {
    padding-top: 50px;
}

body.authentication .card {
    border: 0px !important;
}

.ul-setup-site li {
    font-size: 18px;
    margin: 10px 0;
    padding: 10px 18px 10px;
    line-height: 34px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    list-style: none;
}

.ul-setup-site li.active {
    color: #4F9CF9;
    border: 1px solid #4F9CF9;
    background-color: #FFF;
}

.ul-setup-site li.active i {
    font-weight: 900;
}

.ul-setup-site li i {
    margin-right: 20px;
}

.ul-setup-site li span.btn {
    font-weight: 400;
    float: right;
    color: #4F9CF9;
    background: #FFFFFF;
    border: 1px solid #4F9CF9;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 6px 40px;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.lh-80 {
    line-height: 80px !important;
}

.display-flex {
    display: flex !important;
}

.diskusage {
    border-left: 1px solid #D9D9D9;
    padding-left: 30px;
}

.diskusage div {
    font-size: 42px;
    line-height: 3.4;
}

.diskusage i {
    margin-right: 20px;
}

.site-screenshot {
    width: 320px;
    border-radius: 5px;
}

.feature-card {
    border: 1px solid #ffffff !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
}

.feature-card .card-body {
    padding: 20px;
}

.feature-card h5 {
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0px;
    margin-left: 10px;
}

.feature-card hr {
    margin: 10px 0px;
}

.feature-card p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    min-height: 110px;
}

.feature-card .link {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4F9CF9;
    text-decoration: underline;
}

.nav-second-level {
    padding: 0px;
}

.navbar-collapse {
    display: flex !important;
    flex-basis: auto;
    flex-grow: 1;
    align-items: center;
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav li {
    padding: 16.5px 48px 16.5px 0;
}

.navbar-nav li > a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

#navigation .dropdown button {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-top: 10px;
}

.arrow-down, .arrow-down:after {
    display: inline-block;
}

.arrow-down:after {
    border-color: currentcolor;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: "";
    height: 0.4em;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: top;
    transition: all .3s ease-out;
    width: 0.4em;
}

.collapse {
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #EFEFEF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.collapse .notify-item {
    padding: 7px 10px;
}

.collapse .dropdown-item, .div-submenu:hover {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333 !important;
}

.collapse .dropdown-item:hover {
    background-color: #fff;
    color: #4F9CF9 !important;
}

.collapse .nav-item {
    list-style: none;
    padding: 5px 10px;
}

.collapse .nav-item:not(:last-child) {
    border-bottom: 1px solid #EFEFEF;
}

.collapse li > a {
    margin: 0 10px;
}

.modal-content {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    /*min-height: 320px;*/
}

.modal-header {
    border-bottom: 1px solid #EFEFEF;
}

.modal-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
}

.modal-body {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* color/black/300 */

    color: #333333;
    border-bottom: 1px solid #EFEFEF;
}

.modal-body input {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    height: 21px;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.5);
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
}

.modal-footer button {
    min-width: 120px;
    margin: 8px;
}

.close {
    font-size: 28px;
    font-weight: 500;
    text-transform: uppercase;
    display: none;
}

.form-group label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* color/black/100 */

    color: #000000;
}

.copytoclipboard {
    width: 120px;
}

.desc-textbox {
    color: rgba(0, 0, 0, 0.5);
}

.desc-textbox a, ul.pagination .active {
    color: #4F9CF9;
}

@media (min-width: 1500px) {
    .col-4 {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.icon {
    height: 15px;
    width: 15px;
}

.navigation-menus {
    display: flex;
    flex-wrap: wrap;
}


.navigation-menus li {
    list-style: none;
}

.text-secondary{
    text-transform: uppercase;
}

.app-header .text-secondary{
    font-weight: 600;
    color: #4F9CF9!important;
}

.screen-shot .image-popup .goto{
    font-size: 18px;
}